import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import ProfitLossCards from "./ProfitLossCards";
import DashboardDetail from "./DashboardDetail";
import { Breadcrumbs } from "../../../AbstractElements";
import { H2, H4, H5 } from "../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_DASHBOARD_DATA_URL } from "../../../_helper/url_helper";
import { fetchOwner } from "../../../store/login/action";

const GeneralComponent = () => {
  const dispatch        = useDispatch();
  const loginState      = useSelector((state) => state.login);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchDataHandler();
    dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
  }, []);

  useEffect(() => {
    if (loginState.owner.status === "inactive") {
      localStorage.removeItem("jwttoken");
      window.location.href = "/";
    }
  }, [loginState]);

  useEffect(() => {
    let interval = setInterval(fetchDataHandler, 5000);
    return () => { clearInterval(interval) }
  }, []);

  async function fetchDataHandler() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
      };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_DASHBOARD_DATA_URL, data);

      console.info("resresresresresres",res);

      if (res.data.status === "SUCCESS") {
        setData(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true} className="general-widget">
        <Row>
          <ProfitLossCards data={data} />
        </Row>
        {/* <Row>
          <DashboardDetail />
        </Row> */}

        <Row>
          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5> <i className="fa fa-list-ul"></i> Buy Turnover </h5>
              </div>
              <div className="card-body fw-semibold">
                <div className="d-flex justify-content-between">
                  <h5>Mcx</h5>
                  <p className="">₹{parseFloat(data.buy_turnover_mcx).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Equity</h5>
                  <p className="">₹{parseFloat(data.buy_turnover_nfo).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Cds</h5>
                  <p>₹{parseFloat(data.buy_turnover_cds).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Fx</h5>
                  <p>${parseFloat(data.buy_turnover_fx).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Crypto</h5>
                  <p>${parseFloat(data.buy_turnover_crypto).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Us</h5>
                  <p>${parseFloat(data.buy_turnover_us).toFixed(2)}</p>
                </div>
                
              </div>
            </div>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5> <i className="fa fa-list-ul"></i> Sell Turnover </h5>
              </div>
              
              <div className="card-body fw-semibold">
                <div className="d-flex justify-content-between">
                  <h5>Mcx</h5>
                  <p className="">₹{parseFloat(data.sell_turnover_mcx).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Equity</h5>
                  <p className="">₹{parseFloat(data.sell_turnover_nfo).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Cds</h5>
                  <p>₹{parseFloat(data.sell_turnover_cds).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Fx</h5>
                  <p>${parseFloat(data.sell_turnover_fx).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Crypto</h5>
                  <p>${parseFloat(data.sell_turnover_crypto).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Us</h5>
                  <p>${parseFloat(data.sell_turnover_us).toFixed(2)}</p>
                </div>                
              </div>
            </div>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-list-ul"></i> Total Turnover
                </h5>
              </div>
              
              <div className="card-body fw-semibold">
                <div className="d-flex justify-content-between">
                  <h5>Mcx</h5>
                  <p className="">₹{parseFloat(data.sell_turnover_mcx).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Equity</h5>
                  <p className="">₹{parseFloat(data.sell_turnover_nfo).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Cds</h5>
                  <p>₹{parseFloat(data.sell_turnover_cds).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Fx</h5>
                  <p>${parseFloat(data.sell_turnover_fx).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Crypto</h5>
                  <p>${parseFloat(data.sell_turnover_crypto).toFixed(2)}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Us</h5>
                  <p>${parseFloat(data.sell_turnover_us).toFixed(2)}</p>
                </div>                
              </div>
            </div>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5> <i className="fa fa-users"></i> Active Users </h5>
              </div>
              
              <div className="card-body fw-semibold">
                <div className="d-flex justify-content-between">
                  <h5>Mcx</h5>
                  <p className="">{data.mcx_users}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Equity</h5>
                  <p className="">{data.nfo_users}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Cds</h5>
                  <p>{data.cds_users}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Fx</h5>
                  <p>{data.fx_users}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Crypto</h5>
                  <p>{data.crypto_users}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Us</h5>
                  <p>{data.us_users}</p>
                </div>                
              </div>
            </div>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-dollar"></i> Profit / Loss
                </h5>
              </div>
              <div className="card-body fw-semibold">
                <div className="d-flex justify-content-between">
                <h5>Mcx ₹</h5>
                <p className={(data.profit_and_loss_mcx > 0) ? "text-primary" : "text-danger"}> ₹ {parseFloat(data.profit_and_loss_mcx).toFixed(2)}</p>
                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Equity</h5>
                  <p className={(data.profit_and_loss_nfo > 0) ? "text-primary" : "text-danger"}> ₹ {parseFloat(data.profit_and_loss_nfo).toFixed(2)}</p>
               
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Cds </h5>
                  <p className={(data.profit_and_loss_cds > 0) ? "text-primary" : "text-danger"}> ₹ {parseFloat(data.profit_and_loss_cds).toFixed(2)}</p>
               
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Fx </h5>
                  <p className={(data.profit_and_loss_fx > 0) ? "text-primary" : "text-danger"}> $ {parseFloat(data.profit_and_loss_fx).toFixed(2)}</p>
               
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Crypto</h5>
                  <p className={(data.profit_and_loss_crypto > 0) ? "text-primary" : "text-danger"}> $ {parseFloat(data.profit_and_loss_crypto).toFixed(2)}</p>
                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Us </h5>
                  <p className={(data.profit_and_loss_us > 0) ? "text-primary" : "text-danger"}> $ {parseFloat(data.profit_and_loss_us).toFixed(2)}</p>
              </div>
              </div>
            </div>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-dollar"></i> Brokerage
                </h5>
              </div>
              <div className="card-body fw-semibold">
                <div className="d-flex justify-content-between">
                  <h5>Mcx</h5>
                  <p className="text-success">{parseFloat(data.brokerage_mcx).toFixed(2)}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Equity</h5>
                  <p className="text-success">{parseFloat(data.brokerage_nfo).toFixed(2)}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Cds</h5>
                  <p className="text-success">{parseFloat(data.brokerage_cds).toFixed(2)}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Fx</h5>
                  <p className="text-success">{parseFloat(data.brokerage_fx).toFixed(2)}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Crypto</h5>
                  <p className="text-success">{parseFloat(data.brokerage_crypto).toFixed(2)}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Us</h5>
                  <p className="text-success">{parseFloat(data.brokerage_us).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-suitcase"></i> Active Buy
                </h5>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h5>Mcx</h5>
                  <p className="">{data.active_buy_mcx}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Equity</h5>
                  <p className="">{data.active_buy_nfo}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Cds</h5>
                  <p className="">{data.active_buy_cds}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Fx</h5>
                  <p className="">{data.active_buy_fx}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Crypto</h5>
                  <p className="">{data.active_buy_crypto}</p>                
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Us</h5>
                  <p className="">{data.active_buy_us}</p>
                </div>
              </div>
            </div>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5> <i className="fa fa-suitcase"></i> Active Sell </h5>
              </div>
              <div className="card-body fw-semibold">
                <div className="d-flex justify-content-between">
                  <h5>Mcx</h5>
                  <p className="">{data.active_sell_mcx}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Equity</h5>
                  <p className="">{data.active_sell_nfo}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Cds</h5>
                  <p className="">{data.active_sell_cds}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Fx</h5>
                  <p className="">{data.active_sell_fx}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Crypto</h5>
                  <p className="">{data.active_sell_crypto}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Us</h5>
                  <p className="">{data.active_sell_us}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row>
        <DashboardDetail />
        </Row> */}

      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
