import React, { Fragment } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { H4 } from '../../../AbstractElements';
import { Globe, User, Briefcase, DollarSign } from 'react-feather';
import { useSelector } from 'react-redux';

const ProfitLossCards = (props) => {
    const loginState = useSelector((state) => state.login);
    let myProfitLoss = loginState.owner.type === "super" ? props.data.superadmin_profit_loss : loginState.owner.type === "admin" ? props.data.admin_profit_loss : (props.data.total_profit_loss - props.data.superadmin_profit_loss) - props.data.admin_profit_loss;
    let myBrokerage = loginState.owner.type === "super" ? props.data.super_admin_brokerage : loginState.owner.type === "admin" ? props.data.admin_brokerage : (props.data.brokerage - props.data.super_admin_brokerage) - props.data.admin_brokerage;
    let myProfitLossUsd = loginState.owner.type === "super" ? props.data.superadmin_profit_loss_usd : loginState.owner.type === "admin" ? props.data.admin_profit_loss_usd : (props.data.total_profit_loss_uad - props.data.superadmin_profit_loss_usd) - props.data.admin_profit_loss_usd;
    let myBrokerageUsd = loginState.owner.type === "super" ? props.data.super_admin_brokerage_usd : loginState.owner.type === "admin" ? props.data.admin_brokerage_usd : (props.data.brokerage_usd - props.data.super_admin_brokerage_usd) - props.data.admin_brokerage_usd;
    const EarningData = [
        {
            id: 1,
            classCompo: (props.data.active_profit_loss > 0) ? 'bg-primary' : 'bg-danger',
            icon: <DollarSign />,
            title: 'Active Profit/Loss',
            count: parseFloat(props.data.active_profit_loss).toFixed(2),
            iconWithClass: <DollarSign className="icon-bg" />
        },
        {
            id: 2,
            classCompo: (props.data.active_profit_loss_usd > 0) ? 'bg-primary' : 'bg-danger',
            icon: <DollarSign />,
            title: 'USD Active Profit/Loss',
            count: parseFloat(props.data.active_profit_loss_usd).toFixed(2),
            iconWithClass: <DollarSign className="icon-bg" />
        },
        {
            id: 3,
            classCompo: 'bg-primary',
            icon: <Briefcase />,
            title: 'Active Trades',
            count: props.data.active_trades,
            iconWithClass: <Briefcase className="icon-bg" />
        },
        {
            id: 4,
            classCompo: 'bg-primary',
            icon: <Briefcase />,
            title: 'USD Active Trades',
            count: props.data.active_trades_usd,
            iconWithClass: <Briefcase className="icon-bg" />
        },
        {
            id: 5,
            classCompo: 'bg-primary',
            icon: <User />,
            title: 'Margin Used',
            count: parseFloat(props.data.used_margin).toFixed(2),
            iconWithClass: <User className="icon-bg" />
        },
        {
            id: 6,
            classCompo: 'bg-primary',
            icon: <User />,
            title: 'USD Margin Used',
            count: parseFloat(props.data.used_margin_usd).toFixed(2),
            iconWithClass: <User className="icon-bg" />
        },
        {
            id: 7,
            classCompo: (props.data.total_profit_loss > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Total Profit/Loss',
            count: parseFloat(props.data.total_profit_loss).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },
        {
            id: 8,
            classCompo: 'bg-primary',
            icon: <Globe />,
            title: 'Total Brokerage',
            count: parseFloat(props.data.brokerage).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },
        {
            id: 9,
            classCompo: ((props.data.total_profit_loss - props.data.brokerage) > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Total Net',
            count: parseFloat(props.data.total_profit_loss - props.data.brokerage).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 10,
            classCompo: (props.data.total_profit_loss_usd > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'USD Total Profit/Loss',
            count: parseFloat(props.data.total_profit_loss_usd).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },
        {
            id: 11,
            classCompo: 'bg-primary',
            icon: <Globe />,
            title: 'USD Total Brokerage',
            count: parseFloat(props.data.brokerage_usd).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },
        {
            id: 12,
            classCompo: ((props.data.total_profit_loss_usd - props.data.brokerage_usd) > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'USD Total Net',
            count: parseFloat(props.data.total_profit_loss_usd - props.data.brokerage_usd).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 13,
            classCompo: (myProfitLoss > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'My Profit/Loss',
            count: parseFloat(myProfitLoss).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 14,
            classCompo: 'bg-primary',
            icon: <Globe />,
            title: 'My Brokerage',
            count: parseFloat(myBrokerage).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 15,
            classCompo: ((myProfitLoss - myBrokerage) > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'My Net',
            count: parseFloat(myProfitLoss - myBrokerage).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 16,
            classCompo: (myProfitLoss > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'My Profit/Loss USD',
            count: parseFloat(myProfitLossUsd).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 17,
            // classCompo: 'bg-primary',
            classCompo: (myBrokerageUsd > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'My Brokerage USD',
            count: parseFloat(myBrokerageUsd).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 18,
            classCompo: ((myProfitLossUsd - myBrokerageUsd) > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'USD My Net',
            count: parseFloat(myProfitLossUsd - myBrokerageUsd).toFixed(2),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 19,
            // classCompo: 'bg-white text-dark',
            classCompo: (props.data.active_traders > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Week Active Traders',
            count: props.data.active_traders,
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 20,
            // classCompo: 'bg-white text-dark',
            classCompo: (props.data.profitable_traders > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Week Profitable Traders',
            count: props.data.profitable_traders,
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 21,
            // classCompo: 'bg-white text-dark',
            classCompo: (props.data.weekly_deposit_inr > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Week Deposit INR',
            count: formatNumberWithCustomCommas(Number(props.data.weekly_deposit_inr)),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 22,
            // classCompo: 'bg-dark',
            classCompo: (props.data.weekly_withdraw_inr > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Week Withdraw INR',
            count: formatNumberWithCustomCommas(Number(props.data.weekly_withdraw_inr)),
            iconWithClass: <Globe className="icon-bg" />
        },
        {
            id: 23,
            // classCompo: 'bg-white text-dark',
            classCompo: (props.data.weekly_deposit_usd > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Week Deposit USD',
            count: formatNumberWithCustomCommas(Number(props.data.weekly_deposit_usd)),
            iconWithClass: <Globe className="icon-bg" />
        },

        {
            id: 24,
            // classCompo: 'bg-dark',
            classCompo: (props.data.weekly_withdraw_usd > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Week Withdraw USD',
            count: formatNumberWithCustomCommas(Number(props.data.weekly_withdraw_usd)),
            iconWithClass: <Globe className="icon-bg" />
        },
        {
            id: 25,
            // classCompo: 'bg-dark',
            classCompo: (props.data.client_total_balance_inr > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Clients total balance INR',
            count: formatNumberWithCustomCommas(Number(props.data.client_total_balance_inr)),
            iconWithClass: <Globe className="icon-bg" />
        },
        {
            id: 26,
            // classCompo: 'bg-dark',
            classCompo: (props.data.client_total_balance_usd > 0) ? 'bg-primary' : 'bg-danger',
            icon: <Globe />,
            title: 'Clients total balance USD',
            count: formatNumberWithCustomCommas(Number(props.data.client_total_balance_usd)),
            iconWithClass: <Globe className="icon-bg" />
        },
    ];


    return (
        <Fragment>
            {EarningData.map((item) =>
                <Col sm="6" xl="4" lg="4" key={item.id}>
                    <Card className="o-hidden border-0">
                        <CardBody className={item.classCompo}>
                            <div className="media static-top-widget">
                                <div className="align-self-center text-center">
                                    {item.icon}
                                </div>
                                <div className="media-body">
                                    <span className="m-0">{item.title}</span>
                                    <H4 attrH4={{ className: 'mb-0 counter' }} >{item.count}</H4>
                                    {item.iconWithClass}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            )}
        </Fragment>
    );
};

export default ProfitLossCards;

function formatNumberWithCustomCommas(number) {
    return number.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}