import axios from "axios";
import { useSelector } from "react-redux"; // Import the useSelector hook

const API = axios.create({
    // baseURL: "https://app.tradeyarr.com/api", app.tdmaster.in
    // baseURL: "https://app.tdmaster.in/api", 
    // baseURL: "http://127.0.0.1:8000/api",
    baseURL : "https://app.bastionex.net/api/"

});

export default API;
